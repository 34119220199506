<template>
    <section class="notification">
    <!-- <pageLoader v-if="!array.length"/> -->
    <div class="page-header">
      <h3 class="page-title">
        Notifications
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">Notifications</li>
        </ol>
      </nav>
    </div>
    <div class="row" v-if="display">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <!-- liste des notifications -->
              <div class="col-12 results" :class="{ unread: !notification.isreadonboard}" v-for="notification in paginatedCards" :key="notification.key"
             >
                <div class="pt-4 border-bottom">
                    <div class="d-flex justify-content-end">
                       <b-dropdown variant="link" no-caret toggle-class="text-decoration-none">
                        <template #button-content>
                          <i class="fa fa-ellipsis-h"></i>
                        </template>
                        <b-dropdown-item @click="unreadNotification(notification.ulid, notification.key),$store.dispatch('getNotifications')">Marquer comme non lue</b-dropdown-item>
                        <b-dropdown-item @click="deleteNotification(notification.ulid),$store.dispatch('getNotifications')">Supprimer</b-dropdown-item>
                      </b-dropdown>
                      
                    </div>
                    <div class="d-flex justify-content-between">
                        <h3 class=" d-block h4 mb-0 pointer"  @click="ordersDetails(notification)">
                          <i v-if="notification.object=='reservation'" class=" mdi mdi-calendar text-success"></i>
                          <i v-else-if="notification.object=='cancellation'" class=" mdi mdi-calendar-remove text-danger"></i>
                          <i v-else-if="notification.object=='purchase'" class=" mdi mdi-cart text-info"></i>
                          {{
                             notification.object=="reservation"?"Nouvelle réservation"
                            :notification.object=="cancellation"?"Annulation réservation"
                            :notification.object=="purchase"?"Achat"
                            :"Vous avez une notification"
                          }}
                          </h3>
                        <p class="text-muted">{{formatDate2(notification.created_at)}}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <span class="text-muted" v-html="notification.message"></span>
                        <!-- icone -->
                        <div class="preview-thumbnail">
                          <div v-if="notification.object=='reservation'" class="preview-icon bg-success d-flex justify-content-center align-items-center">
                              <i class="mdi mdi-calendar"></i>
                            </div>
                          <div v-else-if="notification.object=='cancellation'" class="preview-icon bg-danger d-flex justify-content-center align-items-center">
                            <i class="mdi mdi-calendar"></i>
                          </div>
                          <div v-else-if="notification.object=='purchase'" class="preview-icon bg-info d-flex justify-content-center align-items-center">
                            <i class="mdi mdi-cart"></i>
                          </div>
                          <div v-else class="preview-icon bg-primary d-flex justify-content-center align-items-center">
                            <i class="fa fa-bell-o"></i>
                          </div>
                        </div>
                    </div>
                </div>
              </div>

              <b-pagination class="rounded-separated d-flex justify-content-center computer" v-model="currentPage" :per-page="perPage" :total-rows="display.length"></b-pagination>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import pageLoader from '../components/pageLoader.vue'
import { dateMixin } from './mixins/date'
import { notificationsMixin } from './mixins/notifications'
export default {
    name: "notification",
    mixins:[dateMixin,notificationsMixin],
    components:{
      // pageLoader
    },
    data(){
        return{
          loader : true,
          array:[],
          // pagination
          display:[],
          currentPage:1,
          perPage: 5,
          search:''
          }
    },
    computed:{
      paginatedCards() {
      const { currentPage, perPage } = this;
      const start = (currentPage - 1) * perPage;
      const end = currentPage * perPage;
      return this.display.slice(start, end);
      }
    },
    methods:{
      notifications(){
        console.log({notifications});
        let notifications = this.$store.state.notifications.notifications
        this.display = notifications
      },
      ordersDetails(notification){
        if(notification.object !== "cancellation"){
          this.$router.push({
            name:"ordersDetails",
            params:{
              ulid:notification.object_id
            }
          })
          this.readNotification(notification.ulid)
        }
        else{
          alert('Cette réservation a été supprimée')
          this.readNotification(notification.ulid)
        }
      },
      readNotifications(){
        // this.display.filter(notification => !notification.isreadonboard)
        // console.log("read notifications", this.display.filter(notification => notification.isreadonboard == false))
      }
    },
    created(){
      this.notifications()
    },
    mounted(){
      this.readNotifications()
    }
}
</script>
<style scoped>
.preview-icon {
    color:#fff; 
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
}
.unread {
  background-color: #dff9f5
}
/* dropdown button */
::v-deep .b-dropdown .btn {
  padding: 0px;
}
::v-deep .b-dropdown:hover .btn {
  color: rgb(205, 205, 205);
}
::v-deep .client, ::v-deep .date, ::v-deep .hour{
  font-size:1.5rem
}
</style>



